<template>
  <div class="employment-periods-table-wrapper">
    <table class="employment-periods-table">
      <thead>
        <tr>
          <th class="employment-period-column__start-time">Начало</th>
          <th class="employment-period-column__end-time">Конец</th>
          <th class="employment-period-column__work-factor">Коэффициент</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="employmentPeriod in employmentPeriods"
          :key="employmentPeriod.id"
        >
          <td data-test="start-time"
            class="employment-period-column__start-time">
              {{ fromISOToLocaleDateString(employmentPeriod.startAt) }}
            </td>

          <td data-test="end-time"
            class="employment-period-column__end-time">
            {{ fromISOToLocaleDateString(employmentPeriod.endAt) }}
          </td>

          <td class="employment-period-column__work-factor">
            {{ employmentPeriod.workFactor }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
